<script lang="ts" setup>
import { useForm } from 'laravel-precognition-vue-inertia';
import type { Agency, SubscriberList, Template } from 'types';
import { route } from '../composables/routes';
import Autocomplete from './Autocomplete.vue';

const props = defineProps<{
    agency: Agency;
    list: SubscriberList;
    template: Template;
    lists?: SubscriberList[]
    errors?: Record<string,string>;
}>();

const form = useForm('patch', route('templates.transfer', {
    agency: props.agency.slug,
    list: props.list.slug,
    template: props.template.id
}), {
    list_id: undefined
});

defineExpose({
    form
});
</script>

<template>
    <Autocomplete
        id="list_id"
        v-model="form.list_id"
        max-height="20rem"
        namespace="lists"
        only="lists"
        :response="lists"
        :error="errors?.list_id" />
</template>