<script lang="ts" setup>
import { ArrowUpTrayIcon, CheckCircleIcon, MagnifyingGlassIcon, PlusIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { type ModalContext } from '@vue-interface/modal';
import { SelectField } from '@vue-interface/select-field';
import type { Agency, Can, LengthAwarePaginator, Subscriber, SubscriberList, Subscription, User } from 'types';
import { reactive } from 'vue';
import SubscriberIcon from '../../svg/subscriber-icon.svg';
import ChunkNorris from '../components/ChunkNorris.vue';
import Header from '../components/Header.vue';
import Value from '../components/metrics/Value.vue';
import SearchableDataTable from '../components/SearchableDataTable.vue';
import SubscriberForm from '../components/SubscriberForm.vue';
import Tab from '../components/Tab.vue';
import Tabs from '../components/Tabs.vue';
import { route } from '../composables/routes';

const props = defineProps<{
    agency: Agency;
    list: SubscriberList;
    subscribers?: LengthAwarePaginator<Subscriber & Subscription>;
    authUser: User;
    can: Can<['upload']>;
    states: any;
    csrfToken: string;
}>();

const data = reactive<{
    status: 'active' | 'unsubscribed' | undefined
}>({
    status: undefined
});

function onClickConfirm(el: HTMLButtonElement, { close }: ModalContext) {
    // el.disabled = true;

    fetch(route('subscribers.export', {
        agency: props.agency.slug, list: props.list.slug
    }), {
        method: 'POST',
        headers: {
            'X-CSRF-TOKEN': props.csrfToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(async (response) => {
        const match = response.headers.get('content-disposition')?.match(/filename=(.+)/);

        const blobUrl = URL.createObjectURL(await response.blob());

        // Create a temporary anchor element
        const link = document.createElement('a');

        link.href = blobUrl;
        link.download = match?.[1] ?? `${props.list.slug}-subscribers.csv`;
        link.click();

        URL.revokeObjectURL(blobUrl);
            
        link.remove();

        close();
    });
}
</script>

<template>
    <div>
        <Header
            size="lg"
            :icon="SubscriberIcon"
            title="Subscribers">
            <!-- <template #actions>
                <button
                    id="export"
                    type="button"
                    class="btn btn-primary">
                    <CloudArrowDownIcon class="size-6" /> Export
                </button>
            </template> -->
        </Header>

        <div class="grid auto-cols-fr grid-flow-col gap-4 pb-8">
            <Value
                url="metrics/subscribes/value"
                :params="{
                    list: list.id,
                }" />
            <Value
                url="metrics/unsubscribes/value"
                :params="{
                    list: list.id,
                }" />
        </div>

        <Tabs>
            <Tab
                header="Search"
                :icon="MagnifyingGlassIcon">
                <SearchableDataTable
                    :header="false"
                    singular="subscriber"
                    plural="subscribers"
                    :response="subscribers"
                    :table-headers="[{
                        name: 'email',
                        label: 'Email'
                    },{
                        name: 'first',
                        label: 'First Name'
                    },{
                        name: 'last',
                        label: 'Last Name'
                    },{
                        name: 'unsub',
                        label: 'Subscribed'
                    }]"
                    :routes="{
                        index: route('subscribers.index', {
                            agency: agency.slug,
                            list: list.slug
                        }),
                        show: subscriber => route('subscription.show', {
                            agency: agency.slug,
                            list: list.slug,
                            subscriber: subscriber.subscriber_id,
                            subscription: subscriber.id,
                        }),
                        edit: subscriber => route('subscribers.edit', {
                            agency: agency.slug,
                            list: list.slug,
                            subscriber: subscriber.subscriber_id
                        })
                    }">
                    <template #right-filters="{ formData }">
                        <SelectField v-model="formData.status">
                            <option :value="undefined">
                                All
                            </option>
                            <option value="subscribed">
                                Subscribed
                            </option>
                            <option value="unsubscribed">
                                Unsubscribed
                            </option>
                        </SelectField>
                    </template>
                    <template #unsub="{ unsub }">
                        <XCircleIcon
                            v-if="unsub"
                            class="h-6 w-6 text-red-500" />
                        <CheckCircleIcon
                            v-else
                            class="h-6 w-6 text-emerald-500" />
                    </template>
                </SearchableDataTable>
            </Tab>
            <Tab
                v-if="can.create"
                :icon="PlusIcon"
                header="Add Subscriber">
                <SubscriberForm
                    :states="states"
                    :url="route('subscribers.store', { agency: agency.slug, list: list.slug })" />
            </Tab>
            <Tab
                v-if="can.upload"
                :icon="ArrowUpTrayIcon"
                header="Upload File">
                <ChunkNorris :list="list" />
            </Tab>
        </Tabs>
    </div>
</template>