<script lang="ts" setup generic="T extends Record<string,any> | Array<Object>">
import { Bars3Icon } from '@heroicons/vue/24/outline';
import { Link } from '@inertiajs/vue3';
import { Card } from '@vue-interface/card';
import { isValid, parse } from 'date-fns';
import { computed, type Component } from 'vue';
import DetailsViewData from './DetailsViewData.vue';
import Header from './Header.vue';
    
export type BaseField = {
    name: string;
    label?: string;
    defaultValue?: string | number;
    target?: string
}
    
export type NumberField = BaseField & {
    type?: NumberConstructor;
}
    
export type StringField = BaseField & {
    type?: StringConstructor;
}
    
export type BooleanField = BaseField & {
    type?: BooleanConstructor;
    reverse?: boolean
}
    
export type DateField = BaseField & {
    type: DateConstructor;
    format: string;
}
    
export type LinkField = BaseField & {
    type: typeof Link;
}
    
export type ExternalLinkField = BaseField & {
    target?: string
    href: string
}

export type ImageField = BaseField & {
    type: Image;
    class?: string
}
    
export type RelationshipField = BaseField & {
    type: Relationship;
    href: string;
    title?: string | ((data: any) => string);
}
    
export type CustomField = BaseField & {
    type: Component;
    props: (value: any) => Record<string,any>;
}
    
export type Field = StringField
    | NumberField
    | BooleanField
    | DateField
    | LinkField
    | ImageField
    | RelationshipField
    | CustomField;
    
const props = withDefaults(defineProps<{
        data: T;
        header?: string | false;
        fields: (Field|undefined)[];
        icon?: Component | string;
    }>(), {
    header: 'Details',
    icon: Bars3Icon
});
    
const fields = computed(() => props.fields.filter(Boolean) as Field[]);
</script>
    
<script lang="ts">
export class Relationship { }
export class Image { }
export class ExternalLink { }
export class Tags { }

export function checkDataType(value: any) {
    if(Array.isArray(value)) {
        return {
            type: Array
        };
    }
    if(isNumeric(value)) {
        return { 
            type: Number
        };
    }
    if(isBoolean(value)) {
        return {
            type: Boolean
        };
    }
    if(isValidDate(value)) {
        return {
            defaultValue: value,
            type: Date,
            format: 'PPPp'
        };
    }

    return String;
}

export function isNumeric(num: any) {
    return !isNaN(num);
}

export function isBoolean(value: any) {
    return value !== undefined && ['true', 'yes', 'false', 'no'].includes(value.toString().toLowerCase());
}

export function isValidDate(value: Date|string) {
    if(value instanceof Date) {
        return true;
    }

    return isValid(parse(value, 'P', new Date()));
}
</script>
    
<template>
    <div class="w-full">
        <slot name="header">
            <Header
                v-if="header"
                :title="header"
                :icon="icon" />
        </slot>
    
        <Card>
            <table
                v-if="!Array.isArray(data)"
                class="w-full table-fixed text-base">
                <tr
                    v-for="field in fields"
                    :key="field.name"
                    class="border-b border-neutral-300 last:border-0 dark:border-neutral-700">
                    <th class="p-4 text-left align-top">
                        {{ field.label ?? field.name }}
                    </th>
                    <td class="p-4">
                        <slot
                            :name="field.name"
                            :data="(data as Record<string,any>)[field.name]">
                            <DetailsViewData
                                :field="field"
                                :data="props.data" />
                        </slot>
                    </td>
                </tr>
            </table>
            <table
                v-else
                class="w-full table-fixed text-base">
                <thead>
                    <tr>
                        <th
                            v-for="field in fields"
                            :key="`col-${field.name}`"
                            class="p-2 text-left">
                            {{ field.label ?? field.name }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(row, i) in data"
                        :key="i"
                        class="border-b border-neutral-300 last:border-0 dark:border-neutral-700">
                        <th
                            v-for="field in fields"
                            :key="`cell-${i}-${field.name}`"
                            class="p-2 text-left font-normal">
                            <DetailsViewData
                                :field="field"
                                :data="row" />
                        </th>
                    </tr>
                </tbody>
            </table>
        </Card>
    </div>
</template>