<script lang="ts" setup>
import Multiselect from '@vueform/multiselect';
import { ref } from 'vue';

const modelValue = defineModel<any>();

const field = ref();

function focus() {
    field.value?.focus();
}

defineExpose({
    focus
});
</script>

<template>
    <Multiselect
        ref="field"
        v-model="modelValue"
        v-bind="{
            clearOnBlur: false,
            ...$attrs
        }"
        :classes="{
            container: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-neutral-300 dark:border-neutral-500 rounded bg-white dark:bg-neutral-700 text-base leading-snug outline-none',
            tag: 'bg-rose-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
            containerActive: 'ring ring-rose-500 ring-opacity-50 !border-rose-500',
            option: 'dark:bg-neutral-900 dark:text-white p-2 dark:hover:bg-neutral-700',
        }" />
</template>