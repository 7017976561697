<script lang="ts" setup>
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';
import { Card } from '@vue-interface/card';
import { ProgressBar } from '@vue-interface/progress-bar';
import { type ChunkedFile, type MetaData, createDialog, createRunner } from 'chunk-norris';
import type { SubscriberList } from 'types';
import { onMounted, ref, useTemplateRef } from 'vue';
import axios from '../axios';
import Banner from './Banner.vue';
import FormField from './FormField.vue';
import Header from './Header.vue';
import MultiselectField from './MultiselectField.vue';

const props = defineProps<{
    list: SubscriberList
}>();

const tags = ref([]);
const uploader = useTemplateRef('uploader');

const upload = ref<{chunks: ChunkedFile, meta: MetaData}>();
const processing = ref(false);
const percent = ref();
const errors = ref<unknown[]>([]);
const exception = ref<any>();

const { mount, toggle, close } = createDialog({
    open: false,
    fields: [
        {
            'label': 'Email',
            'key': 'email',
            'alternates': [
                'email_address',
                'Email Address'
            ],
            'validators': [
                {
                    'validate': 'required'
                }
            ]
        },
        {
            'label': 'First',
            'key': 'first',
            'alternates': [
                'first_name',
                'First Name'
            ]
        },
        {
            'label': 'Last',
            'key': 'last',
            'alternates': [
                'last_name',
                'Last Name'
            ]
        },
        {
            'label': 'Street',
            'key': 'street',
            'alternates': [
                'address',
                'address_1',
                'address_line_1',
            ]
        },
        {
            'label': 'City',
            'key': 'city',
            'alternates': [
                'address_city',
            ]
        },
        {
            'label': 'State',
            'key': 'state',
            'alternates': [
                'address_state',
            ]
        },
        {
            'label': 'Zip',
            'key': 'zip',
            'alternates': [
                'zipcode',
                'postal',
                'address_zip',
                'address_postal'
            ]
        },
        {
            'label': 'Phone',
            'key': 'phone',
            'alternates': [
                'Phone Number',
                'phone_number',
            ]
        },
        {
            'label': 'Tags',
            'key': 'tags'
        },
    ],
    onComplete(chunks, meta) {
        upload.value = { chunks, meta };
    }
});

function reset() {
    tags.value = [];
    errors.value = [];
    exception.value = undefined;
    percent.value = undefined;
    processing.value = false;
    upload.value = undefined;
}

function onClickUpload() {
    return new Promise<void>(async (resolve, reject) => {
        if(!upload.value) {
            return reject(new Error('There is no file to upload.'));
        }

        exception.value = undefined;
        errors.value = [];
        processing.value = true;
        percent.value = 0;

        const { data: { upload: { id } } } = await axios.post<{upload: { id: number }}>('elixr/uploads/begin', {
            catalog: props.list.elixr_catalog_id,
            tags: tags.value.join(','),
            filename: upload.value.meta.name,
            totalRecords: upload.value.meta.totalLines,
            initiated: 'alchemy/subscribers'
        }).catch(e => {
            reject(e);

            throw e;
        });
        
        const run = createRunner(upload.value.chunks.map(data => {
            return () => axios.post<{total_lines: number}>(`elixr/uploads/${id}/chunk`, { data })
                .catch(e => {
                    reject(e);

                    throw e;
                });
        }), 10, 2);
    
        run({
            async onFinish(success, { errors }) {
                if(!success) {
                    reject(errors);

                    return;
                }
                
                await axios.post(`elixr/uploads/${id}/complete`).catch(e => {
                    reject(e);

                    throw e;
                });

                resolve();
            },
            onProgress(_, value) {
                percent.value = value;
            },
            onError(error) {
                errors.value.push(error);
            }
        });
    }).catch(e => {
        exception.value = e;
    }).finally(reset);
}

onMounted(() => {
    if(!uploader.value) {
        return;
    }

    mount(uploader.value);
});

router.on('navigate', () => {
    close();
});
</script>

<template>
    <div class="flex flex-col gap-8">
        <Banner
            v-if="exception"
            class="bg-rose-600 dark:bg-rose-600 text-white flex">
            <ExclamationTriangleIcon class="w-8 h-8" />
            <div>
                {{ exception?.response?.data?.error ?? exception }}
            </div>
        </Banner>

        <div class="flex flex-col gap-2">
            <Header title="Details" />

            <Card>
                <div class="flex flex-col gap-4">
                    <FormField
                        label="File"
                        description="File types that are accepted: .csv, .txt, .tsc"
                        required>
                        <button
                            v-if="!upload"
                            type="button"
                            class="btn-outline-primary btn flex"
                            @click="toggle">
                            Select File 
                        </button>
                        <div
                            v-else
                            class="flex items-center gap-4">
                            {{ upload.meta.name }}
                            <button
                                type="button"
                                class="btn-secondary btn btn-sm flex"
                                @click="upload = undefined">
                                Cancel 
                            </button>
                        </div>
                    </FormField>
                    <FormField
                        label="Tags"
                        class="flex items-center">
                        <MultiselectField
                            v-model="tags"
                            mode="tags"
                            :create-option="true"
                            :searchable="true"
                            :options="[
                                'Donor',
                                'Survey',
                                'Petition',
                                'Recurring',
                                'DM',
                                'TM'
                            ]"
                            :allow-false="true" />
                    </FormField>
                </div>
            </Card>
        </div>

        <div
            v-if="upload"
            class="flex flex-col gap-2">
            <Header title="Data File" />

            <Card>
                <div class="flex flex-col gap-4">
                    <FormField label="File Size">
                        {{ upload.meta.size.formatted }}
                    </FormField>
                    <FormField label="Total Rows">
                        {{ upload.meta.totalLines.toLocaleString('en-US') }}
                    </FormField>
                    <FormField label="Mapped Headers">
                        <table class="w-full table-default table-fixed">
                            <thead>
                                <tr>
                                    <th class="text-left white-space-nowrap uppercase text-sm tracking-wide p-2 border border-neutral-200 dark:border-neutral-600">
                                        Column
                                    </th>
                                    <th class="text-left white-space-nowrap uppercase text-sm tracking-wide p-2 border border-neutral-200 dark:border-neutral-600">
                                        Field
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(map, key) in upload.meta.mappedHeaders"
                                    :key="key">
                                    <td class="p-2 text-gray-500 border border-neutral-200 dark:border-neutral-600 divide-y divide-gray-100 dark:divide-gray-700">
                                        {{ key }}
                                    </td>
                                    <td class="p-2 text-gray-500 border border-neutral-200 dark:border-neutral-600 divide-y divide-gray-100 dark:divide-gray-700">
                                        {{ map && !map.ignore ? map.key : '&mdash;' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </FormField>
                </div>
            </Card>
        </div>
    
        <ProgressBar
            v-if="typeof percent === 'number'"
            :value="percent * 100"
            height="5px" />

        <div class="flex gap-2 justify-end">
            <button
                :disabled="!upload || processing"
                class="btn-primary btn flex"
                @click="onClickUpload()">
                Upload
            </button>
        </div>
    </div>    
    
    <Teleport to="body">
        <div
            ref="uploader"
            class="chunk-norris" />
    </Teleport>
</template>