<script lang="ts" setup>
import { ExclamationTriangleIcon, PlusIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';
import { ActivityIndicator, Dots } from '@vue-interface/activity-indicator';
import { InputField } from '@vue-interface/input-field';
import { InputList } from '@vue-interface/input-list';
import { LightSwitchField } from '@vue-interface/light-switch-field';
import { SelectField } from '@vue-interface/select-field';
import { TextareaField } from '@vue-interface/textarea-field';
import type { AxiosRequestConfig } from 'axios';
import { useForm } from 'laravel-precognition-vue-inertia';
import type { Agency, MgDataVariable, SubscriberList } from 'types';
import { onMounted, ref } from 'vue';
import { useToast } from 'vue-toastification';
import axios from '../axios';
import DragHandleIcon from '../components/icons/DragHandle.vue';
import { route } from '../composables/routes';
import { focus } from '../utils/utils';
import Draggable, { vDragHandle } from './Draggable.vue';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';
import InputTable from './InputTable.vue';
import SelectFieldCriterion from './segmentation-criteria/SelectFieldCriterion.vue';

const props = defineProps<{
    agency: Agency,
    list: SubscriberList,
}>();

const toast = useToast();
const activity = ref(false);

const url = route('agencies.lists.update', {
    agency: props.agency.id,
    list: props.list.id
});

const form = useForm('patch', url, {
    options: {
        custom_fonts: props.list.options?.custom_fonts ?? [
            {
                url: null,
                font_family: null,
            }
        ],
        smart_merge_tags: props.list.options?.smart_merge_tags ?? [
            {
                name: null,
                value: null,
                previewValue: null,
            }
        ],
        mg_data_variables: props.list.options.mg_data_variables ?? [],
    }
});

const dragItem = ref<MgDataVariable>();

function onDragenter(e: DragEvent, index: number) {
    if(!dragItem.value) {
        return;
    }

    form.options.mg_data_variables.splice(
        form.options.mg_data_variables.indexOf(dragItem.value), 1
    );

    form.options.mg_data_variables.splice(index, 0, dragItem.value);
}

function onSubmit() {
    form.submit('patch', url, {
        onSuccess: () => {
            router.visit(route('agencies.lists.show', {
                agency: props.agency.id,
                list: props.list.id
            }));
            
            toast.success(`${props.list.name} has been updated!`);
        },
        onError(e) {
            const field = Object.keys(e)?.[0];
            
            if(field) {
                focus(field);
            }
        },
        preserveScroll: true
    });
}

function remove(item: any[], index: number) {
    item.splice(index, 1);
}

async function getSegmentationCriteria(config?: AxiosRequestConfig) {
    activity.value = true;

    return await axios.get(route('api.list-mg-data-variables', { list: props.list.id }), config)
        .then(response => {
            form.options.mg_data_variables = response.data;

            return response.data;
        }).finally(() => {
            activity.value = false;
        });
}

async function reset() {
    if(confirm('Are you sure you want to reset the MessageGears Data Variables?')) {
        await getSegmentationCriteria({
            params: { reset: true }
        });
    }
}

function onUpdateType(value: string, index: number) {
    if(value !== 'TextField') {
        // delete form.options.mg_data_variables[index].validationRules;
    }
}

onMounted(async () => {
    await getSegmentationCriteria();
});
</script>

<template>
    <FormLayout
        :form="form"
        :submit="onSubmit"
        button-label="Save">
        <div class="flex flex-col gap-y-8">
            <div class="rounded bg-white shadow dark:bg-neutral-800">
                <header class="border-b p-2 dark:border-b-neutral-900">
                    <h2 class="font-semibold">
                        Bee Editor Custom Fonts
                    </h2>
                </header>

                <InputList
                    v-slot="{ index, item, isLastRow, add }"
                    :items="form.options?.custom_fonts">
                    <FormField
                        label="Google Font URL"
                        description="The Google Font URL.">
                        <div
                            class="mb-2 flex items-center justify-center gap-x-2">
                            <InputField
                                :id="`options.custom_fonts.${index}.url`"
                                v-model="item.url"
                                :name="`options.custom_fonts.${index}.url`"
                                :error="(form.errors as Record<string,string>)[`options.custom_fonts.${index}.url`]"
                                placeholder="https://fonts.googleapis.com/css?family=Lobster"
                                class="w-full" />
                            <button
                                v-if="isLastRow"
                                :disabled="!item.url || !item.font_family"
                                class="btn btn-neutral-300 dark:btn-neutral-900"
                                @click="add({ url: null, font_family: null })">
                                <PlusIcon
                                    class="h-6 w-6" />
                            </button>
                            <button
                                v-else
                                class="btn btn-red-500"
                                @click="remove(form.options?.custom_fonts, index)">
                                <TrashIcon class="h-6 w-6 text-neutral-200" />
                            </button>
                        </div>
                    </FormField>
                    <FormField
                        label="CSS Font Family"
                        description="The font-family CSS property specifies a prioritized list of one or more font family names and/or generic family names. Values are separated by commas to indicate that they are alternatives.">
                        <InputField
                            :id="`options.custom_fonts.${index}.font_family`"
                            v-model="item.font_family"
                            :name="`options.custom_fonts.${index}.font_family`"
                            placeholder="'Lobster', Georgia, Times, serif"
                            :error="(form.errors as Record<string,string>)[`options.custom_fonts.${index}.font_family`]" />
                    </FormField>
                </InputList>
            </div>

            <div class="rounded bg-white shadow dark:bg-neutral-800">
                <header class="border-b p-2 dark:border-b-neutral-900">
                    <h2 class="font-semibold">
                        MessageGears Merge Tags
                    </h2>
                </header>
                <table class="w-full">
                    <thead>
                        <tr>
                            <th
                                width="31%"
                                class="p-2 text-center">
                                Name
                            </th>
                            <th
                                width="31%"
                                class="pt-2 text-center">
                                Value
                            </th>
                            <th
                                width="31%"
                                class="pt-2 text-center">
                                Preview Value
                            </th>
                            <th
                                class="pt-2 text-left">
                                {{ null }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <InputTable
                            v-slot="{ index, item: row, isLastRow, add }"
                            :items="form.options.smart_merge_tags">
                            <td class="p-2">
                                <InputField
                                    v-model="row.name"
                                    :name="`options.smart_merge_tags.${index}.name`"
                                    :error="(form.errors as Record<string,string>)[`options.smart_merge_tags.${index}.name`]"
                                    class="w-full p-2"
                                    type="text" />
                            </td>
                            <td class="p-2">
                                <InputField
                                    v-model="row.value"
                                    placeholder="${Recipient.FirstName}"
                                    :name="`options.smart_merge_tags.${index}.value`"
                                    :error="(form.errors as Record<string,string>)[`options.smart_merge_tags.${index}.value`]"
                                    class="w-full p-2"
                                    type="text" />
                            </td>
                            <td class="p-2">
                                <InputField
                                    v-model="row.previewValue"
                                    :name="`options.smart_merge_tags.${index}.previewValue`"
                                    :error="(form.errors as Record<string,string>)[`options.smart_merge_tags.${index}.previewValue`]"
                                    class="w-full p-2"
                                    type="text" />
                            </td>
                            <td class="p-2 text-center">
                                <button
                                    v-if="isLastRow"
                                    :disabled="!row.name || !row.value || !row.previewValue"
                                    class="btn btn-neutral-300 dark:btn-neutral-900"
                                    @click="add({
                                        name: null,
                                        value: null,
                                        previewValue: null,
                                    })">
                                    <PlusIcon
                                        class="h-6 w-6" />
                                </button>
                                <button
                                    v-else
                                    class="btn btn-red-500"
                                    @click="remove(form.options?.smart_merge_tags, index)">
                                    <TrashIcon class="h-6 w-6 text-neutral-200" />
                                </button>
                            </td>
                        </InputTable>
                    </tbody>
                </table>
            </div>

            <div
                class="rounded bg-white shadow dark:bg-neutral-800">
                <header class="border-b p-2 dark:border-b-neutral-900 flex justify-between">
                    <h2 class="font-semibold">
                        MessageGears Data Variables
                    </h2>
                    <button
                        class="text-rose-500"
                        type="button"
                        @click="reset()">
                        Reset All
                    </button>
                </header>

                <ActivityIndicator
                    v-if="activity"
                    center
                    size="sm"
                    class="p-4"
                    :type="Dots" />

                <div
                    v-else-if="!form.options?.mg_data_variables.length"
                    class="flex items-center rounded py-3">
                    <ExclamationTriangleIcon
                        class="mx-3 h-8 w-8 text-yellow-500" />
                    <span class="text-neutral-600 dark:text-neutral-400">
                        The <strong>MessageGears</strong> Audience associated with this list does not have any data variables.
                    </span>
                </div>

                <template v-else>
                    <TransitionGroup
                        move-class="pointer-events-none transition-all duration-300"
                        enter-from-class="-translate-y-full">
                        <Draggable
                            v-for="(item, index) in (form.options.mg_data_variables as MgDataVariable[])"
                            :key="item.id"
                            v-slot="{ handle }"
                            @mousedown="dragItem = item"
                            @mouseup="dragItem = undefined"
                            @dragstart="dragItem = item;"
                            @dragenter="e => onDragenter(e, index)"
                            @dragend="dragItem = undefined">
                            <FormField
                                :class="{
                                    'bg-neutral-100 dark:bg-neutral-900': item !== dragItem && index % 2 > 0,
                                    'bg-neutral-200 dark:bg-neutral-700 relative z-50': item === dragItem
                                }">
                                <template #label="{id}">
                                    <DragHandleIcon
                                        v-drag-handle="handle"
                                        class="w-6 h-6 text-neutral-500 cursor-move" />
                                    <label
                                        :for="id"
                                        class="block font-medium text-base text-neutral-700 dark:text-neutral-200">
                                        {{ item.label }}
                                    </label>
                                </template>
                                <div class="flex flex-col gap-3">
                                    <SelectField
                                        :id="`options.mg_data_variables.${index}.type`"
                                        v-model="item.type"
                                        :name="`options.mg_data_variables.${index}.type`"
                                        :error="(form.errors as Record<string,string>)[`options.mg_data_variables.${index}.type`]"
                                        label="Type"
                                        class="w-full"
                                        @update:model-value="value => onUpdateType(value, index)">
                                        <option
                                            v-for="{ type, label } in list.field_types"
                                            :key="type"
                                            :value="type">
                                            {{ label }}
                                        </option>
                                    </SelectField>
                                    <InputField
                                        v-if="item.type === 'TextField'"
                                        ref="rules"
                                        v-model="item.validationRules"
                                        label="Validation Rules"
                                        :name="`options.mg_data_variables.${index}.validationRules`"
                                        class="w-full"
                                        :error="(form.errors as Record<string,string>)[`options.mg_data_variables.${index}.validationRules`]" />
                                    <TextareaField
                                        v-model="item.helpText"
                                        label="Help Text"
                                        :autogrow="false" />
                                    <SelectFieldCriterion
                                        v-if="item.type === 'SelectField'"
                                        v-bind="{
                                            item,
                                            index,
                                            errors: form.errors,
                                        }" />
                                    <div class="flex flex-col">
                                        <LightSwitchField
                                            v-if="item.type === 'MultiMailingIdField'"
                                            v-model="item.validationRules"
                                            label="Required"
                                            class="light-switch-field-md"
                                            on-value="not_in_array:NA"
                                            :off-value="undefined" />
                                    </div>
                                </div>
                            </FormField>
                        </Draggable>
                    </TransitionGroup>
                </template>
            </div>
        </div>
    </FormLayout>
</template>