<script lang="ts" setup>
import { ArrowsRightLeftIcon, ClipboardDocumentListIcon, DocumentDuplicateIcon, PencilSquareIcon, RectangleGroupIcon, Square3Stack3DIcon, StarIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { Link, router } from '@inertiajs/vue3';
import { Confirm } from '@vue-interface/modal';
import type { Agency, Audit, Can, LengthAwarePaginator, SavedRow, SubscriberList, Template, User } from 'types';
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import type { ComponentExposed } from 'vue-component-type-helpers';
import { useToast } from 'vue-toastification';
import ActionButton from '../components/ActionButton.vue';
import DetailsView, { Relationship } from '../components/DetailsView.vue';
import HtmlPreview from '../components/HtmlPreview.vue';
import SearchableListGroup from '../components/SearchableListGroup.vue';
import Show from '../components/Show.vue';
import TransferForm from '../components/TransferForm.vue';
import BadgeDescription from '../components/audits/BadgeDescription.vue';
import Description from '../components/audits/Description.vue';
import IconDescription from '../components/audits/IconDescription.vue';
import { route } from '../composables/routes';
import { titleCase } from '../composables/titleCase';
import { echo } from '../plugins/Echo';

const props = defineProps<{
    agency: Agency;
    audits?: LengthAwarePaginator<Audit>;
    authUser: User;
    errors?: Record<string,string>;
    list: SubscriberList;
    lists?: SubscriberList[];
    savedRows?: LengthAwarePaginator<SavedRow>;
    savedRowCan: Can;
    template: Template;
}>();

const only = ['savedRows', 'audits'];

const toast = useToast();
const transferForm = ref<ComponentExposed<typeof TransferForm>>();

function setAsDefaultTemplate() {
    router.patch(route('agencies.lists.update', { agency: props.agency.id, list: props.list.id }), {
        template_id: props.template.id
    }, {
        only: [...only, 'template'],
        onSuccess: () => {
            toast.success(`${props.template.name} is now the default template.`);
        }
    });
}

function replicate() {
    if(confirm(`Are you sure you want to duplicate ${props.template.name}?`)) {
        router.post(route('templates.replicate', {
            agency: props.agency.slug,
            list: props.list.slug,
            template: props.template.id
        }));
    }
}

function onSubmitTransferForm(_button: HTMLButtonElement, context: { close: Function }) {
    transferForm.value?.form.submit({
        only: [...only, 'template', 'errors', 'flash'],
        onSuccess() {
            document.body.style.removeProperty('overflow');

            context.close();
        },
    });
}

onBeforeMount(() => {
    echo.private(`App.Models.Template.${props.template.id}`)
        .listen('.TemplateUpdated', () => router.reload({ only: [...only, 'template'] }))
        .listen('.TemplateDeleted', () => router.reload({ only: [...only, 'template'] }))
        .listen('.AuditCreated', () => router.reload({ only: [...only, 'template'] }))
        .listen('.AuditUpdated', () => router.reload({ only: [...only, 'template'] }))
        .listen('.AuditDeleted', () => router.reload({ only: [...only, 'template'] }));
});

onBeforeUnmount(() => {
    echo.private(`App.Models.Template.${props.template.id}`)
        .stopListening('.TemplateUpdated')
        .stopListening('.TemplateDeleted')
        .stopListening('.AuditCreated')
        .stopListening('.AuditUpdated')
        .stopListening('.AuditDeleted');
});
</script>

<template>
    <Show
        :title="template.name"
        :icon="RectangleGroupIcon"
        :badges="template.list?.template_id === template.id ? ['Default'] : undefined">
        <template #actions>
            <ActionButton v-if="template.can?.update && template.can?.delete">
                <Link
                    v-if="template.can.update"
                    :href="route('templates.edit', {
                        agency: agency.slug,
                        list: list.slug,
                        template: template.id
                    })"
                    class="group flex items-center">
                    <PencilSquareIcon
                        class="my-1 mr-3 h-5 w-5" /> Edit Template
                </Link>
                <button
                    class="group flex items-center"
                    @click="replicate">
                    <DocumentDuplicateIcon class="my-1 mr-3 h-5 w-5" /> Duplicate Template
                </button>
                <template v-if="template.list?.template_id !== template.id">
                    <button
                        v-if="authUser.super_admin"
                        id="transfer"
                        class="group flex items-center">
                        <ArrowsRightLeftIcon class="my-1 mr-3 h-5 w-5" /> Transfer
                    </button>
                    <button
                        v-if="template.list?.can?.update"
                        class="group flex items-center"
                        @click="setAsDefaultTemplate">
                        <StarIcon class="my-1 mr-3 h-5 w-5" /> Set as List Default
                    </button>
                    <hr v-if="template.can.delete">
                    <Link
                        v-if="template.can.delete"
                        class="group flex items-center"
                        method="delete"
                        :href="route('templates.destroy', {
                            agency: agency.slug,
                            list: list.slug,
                            template: template.id
                        })"
                        as="button">
                        <TrashIcon class="my-1 mr-3 h-5 w-5" /> Delete Template
                    </Link>
                </template>
            </ActionButton>
        </template>

        <Confirm
            trigger="#transfer"
            :icon="ArrowsRightLeftIcon"
            confirm-label="Transfer"
            :colors="{
                info: 'bg-rose-100 text-rose-400',
            }"
            title="Transfer to List"
            close-button
            @confirm="onSubmitTransferForm">
            <TransferForm
                ref="transferForm"
                :agency="agency"
                :list="list"
                :lists="lists"
                :template="template"
                :errors="errors" />
        </Confirm>

        <HtmlPreview :html="template.iframe_html || template.html || template.no_html_preview" />

        <DetailsView
            :data="template"
            :fields="[
                (
                    template.list?.can?.view ? {
                        name: 'list.name',
                        label: 'List',
                        type: Relationship,
                        href: route('agencies.lists.show', {
                            agency: agency?.id,
                            list: list?.id,
                        })
                    } : { name: 'list.name', label: 'List'}
                ),
                { name: 'updated_at', label: 'Updated', type: Date, format: 'PPPp' },
                { name: 'created_at', label: 'Created', type: Date, format: 'PPPp' },
            ]" />

        <SearchableListGroup
            v-if="savedRowCan.viewAny"
            size="md"
            singular="saved row"
            plural="saved rows"
            namespace="saved-rows"
            :only="only"
            :response="savedRows"
            :icons="{
                default: Square3Stack3DIcon
            }"
            :title="savedRow => savedRow.name"
            :can="{
                view: savedRow => savedRow.can?.view,
                update: savedRow => savedRow.can?.update,
                delete: savedRow => savedRow.can?.delete,
                create: savedRowCan.create
            }"
            :routes="{
                index: route('templates.show', {
                    agency: agency.slug,
                    list: list.slug,
                    template: template.id
                }),
                show: model => route('templates.saved-rows.show', { 
                    agency: agency.slug,
                    list: list.slug,
                    template: template.id,
                    saved_row: model.id
                }),
                edit: model => route('templates.saved-rows.edit', { 
                    agency: agency.slug,
                    list: list.slug,
                    template: template.id,
                    saved_row: model.id
                }),
                create: route('templates.saved-rows.create', {
                    agency: agency.slug,
                    list: list.slug,
                    template: template.id
                }),
                delete: model => route('templates.saved-rows.destroy', { 
                    agency: agency.slug,
                    list: list.slug,
                    template: template.id,
                    saved_row: model.id
                }),
            }">
            <template #badges="{ model }">
                <span
                    v-if="model.type"
                    class="badge"
                    :class="{
                        'bg-orange-500': model.type === 'ad', 
                        'bg-blue-500': model.type === 'feed_story', 
                    }">
                    {{ titleCase(model.type) }}
                </span>
            </template>
        </SearchableListGroup>

        <SearchableListGroup
            size="md"
            :icons="{
                default: ClipboardDocumentListIcon
            }"
            header="Activity"
            plural="audits"
            :filters="false"
            :response="audits"
            :only="only">
            <template #icon="{ model }">
                <IconDescription
                    v-if="model.description?.icon"
                    :description="model.description.icon" />
            </template>
            <template #badges="{ model }">
                <BadgeDescription
                    v-if="model.description?.badge"
                    :description="model.description.badge" />
            </template>
            <template #title="{ model }">
                <Description
                    v-if="model.description"
                    :description="model.description" />
                <template v-else>
                    Audit description not available.
                </template>
            </template>
        </SearchableListGroup>
    </Show>
</template>